<template>
    <div class="search" :style="[manHinh == 'ngang' ? 'width: 75%' : '']">
        <div
            class="btn-style row align-center justify-space-between"
            :style="`${styleThanhTimKiem};background-color: white;`"
        >
            <div>
                <DxButton
                    id="btn-home"
                    type="normal"
                    styling-mode="text"
                    icon="mdi mdi-home"
                    height="56px"
                    width="56px"
                    class="ml-2"
                    @click="$router.push('/')"
                />
            </div>
            <DxTextBox
                id="txt-search"
                v-model="getTimKiem"
                mode="text"
                height="68px"
                width="100%"
                placeholder="Bạn đang muốn đi đâu?"
                styling-mode="text"
                class="ml-3"
                ref="refTimKiem"
                :onKeyUp="onKeyUpTimKiem"
                :onFocusIn="onFocusInTimKiem"
                :onFocusOut="onFocusOutTimKiem"
            />
            <div>
                <div class="row align-center">
                    <DxButton
                        id="btn-clear"
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-close"
                        height="56px"
                        width="56px"
                        class="mx-3"
                        @click="clear"
                    />
                    <div
                        style="
                            width: 2px;
                            height: 24px;
                            background-color: #c8c8c8;
                        "
                    ></div>
                    <DxButton
                        id="btn-mic"
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-microphone"
                        height="56px"
                        width="56px"
                        class="ml-3"
                        @click="$store.commit('trangChu/setDialogDangPhatTrien',true)"
                    />
                    <DxButton
                        id="btn-sound"
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-volume-high"
                        height="56px"
                        width="56px"
                        class="mx-3"
                        @click="$store.commit('trangChu/setDialogDangPhatTrien',true)"
                    />
                    <div
                        v-if="!getDialogLocDiemDen"
                        style="
                            width: 2px;
                            height: 24px;
                            background-color: #c8c8c8;
                        "
                        class="mx-1"
                    ></div>
                    <DxButton
                        v-if="!getDialogLocDiemDen"
                        id="btn-search"
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-magnify"
                        height="56px"
                        width="56px"
                        class="mx-3"
                        @click="timKiem"
                    />
                </div>
            </div>
        </div>
        <LocDiemDenVue v-show="getDialogLocDiemDen" @timKiem="timKiem" />
        <!--=============================lựa chọn kết quả tìm kiếm==================================-->
        <div
            v-show="getDialogDsChonDiemDen"
            :class="`shadow white--bg ${
                getDialogDsChonDiemDen ? 'luachontimkiem' : ''
            }`"
            :style="`${
                !getDialogDsChonDiemDen
                    ? 'height: 0px;border-radius: 16px;'
                    : 'height: auto;border-radius: 0px 0px 16px 16px;border-top:1px solid #ddd;'
            } transition: width 2s;overflow-y:auto;`"
        >
            <div
                v-show="getDialogDsChonDiemDen && getDataDsDiemDen.length > 0"
                v-for="(item, index) in getDataDsDiemDen"
                :key="index"
                class="container content-diemden"
                @click="chonDiemDen(item)"
            >
                <div v-html="boldSearch(item)"></div>
                <div class="text-xs-left font-20" style="padding-left: 6px">
                    {{ item.tenViTri }}
                </div>
            </div>
            <div
                v-show="getDataDsDiemDen.length <= 0"
                class="container text-xs-left"
            >
                Không có dữ liệu!
            </div>
        </div>
        <!--=============================chip==================================-->
        <div class="row mt-2">
            <div v-for="(item, index) in data.chips" :key="index" class="mr-2">
                <DxButton
                    :id="`chip-${index}`"
                    :text="item.text"
                    class="rounder chips"
                    type="normal"
                    styling-mode="contained"
                />
            </div>
        </div>
        <!--=============================Không có bản đồ==================================-->
        <div v-if="!map" class="font-32 pt-5 gray--text">
            Không có dữ liệu bản đồ!<br />
        </div>
    </div>
</template>
<script>
import { DxButton, DxTextBox } from "devextreme-vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import LocDiemDenVue from "./LocDiemDen";
export default {
    components: {
        DxButton,
        DxTextBox,
        LocDiemDenVue,
    },
    props: {
        manHinh: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tempTimeOutTk: null,
            tempTimKiem: "",
            data: {
                chips: [
                    //Chưa làm
                    // { id: 1, text: "Bến xe Thái Nguyên" },
                    // { id: 2, text: "Tuyến Thái Nguyên - Hà Nội" },
                    // { id: 3, text: "Nhà xe Phương Trang" },
                    // { id: 4, text: "20A-1234" },
                ],
            },
        };
    },
    computed: {
        ...mapGetters("timKiemChuyenDi", [
            "getTimKiem",
            "getDataDsDiemDen",
            "dialogDsChuyenDi",
            "getDialogDsChonDiemDen",
            "getDialogLocDiemDen",
            "getTimKiemTheoViTri",
            "getTinhDangChon",
            "getHuyenDangChon",
            "getXaDangChon",
            "getDsTinh",
            "getDsHuyen",
            "getDsXa",
        ]),
        ...mapGetters("banDo", ["map"]),
        styleThanhTimKiem() {
            let style = "";
            if (this.getDialogDsChonDiemDen || this.getDialogLocDiemDen) {
                style =
                    "border-top-left-radius: 16px;border-top-right-radius: 16px;border-bottom: unset;";
            } else {
                style = "border-radius: 16px;";
            }
            return style;
        },
    },
    methods: {
        ...mapMutations("timKiemChuyenDi", {
            setTimKiem: "setTimKiem",
            setModel: "setModel",
            setDialogDsChonDiemDen: "setDialogDsChonDiemDen",
            setGuidNhaXe: "setGuidNhaXe",
            setTimKiemTheoViTri: "setTimKiemTheoViTri",
            setOldDiemDung: "setOld",
            setTinhDangChon: "setTinhDangChon",
            setHuyenDangChon: "setHuyenDangChon",
            setXaDangChon: "setXaDangChon",
        }),
        ...mapMutations("banDo", ["set", "setOld"]),
        ...mapActions("timKiemChuyenDi", [
            "layDanhSachDiemDen",
            "layDanhSachChuyenDi",
            "layDanhSachLoTrinhSapXuatBen",
            "xoaLocTheoDiaDiem",
        ]),
        ...mapActions("banDo", [
            "xoaMarkers",
            "drawMarker",
            "xoaDsLoTrinh",
            "xoaDiemDung",
            "layThongTinViTriDangChon",
        ]),
        timKiem() {
            this.xoaMarkers();
            this.setGuidNhaXe("");
            if (this.getTimKiemTheoViTri) {
                this.xoaDsLoTrinh();
                this.xoaDiemDung();
                this.setOld({ key: "loTrinh", value: [] });
                this.setOldDiemDung({ key: "diemDung", value: "" });
                this.layDanhSachChuyenDi({});
                this.setTimKiemTheoViTri(false);
                this.drawMarker();
                this.layThongTinViTriDangChon()
                return;
            }
            //Tìm kiếm theo tỉnh, huyện, xã
            if (
                this.getTinhDangChon ||
                this.getHuyenDangChon ||
                this.getXaDangChon
            ) {
                this.timKiemTheoTinhHuyenXa();
                return;
            }
            if (this.getDataDsDiemDen.length > 0) {
                //Chọn điểm đến đầu tiên
                this.chonDiemDen(this.getDataDsDiemDen[0]);
            } else {
                this.layDanhSachDiemDen();
            }
        },
        async timKiemTheoTinhHuyenXa() {
            this.$startLoading;
            this.xoaMarkers();
            this.setGuidNhaXe("");
            let placeMarkId = this.getXaDangChon
                ? this.getXaDangChon.PlacemarkId
                : this.getHuyenDangChon
                ? this.getHuyenDangChon.PlacemarkId
                : this.getTinhDangChon
                ? this.getTinhDangChon.PlacemarkId
                : "";
            this.setModel({
                key: "diemDenDangChon",
                value: {
                    placemarkId: placeMarkId,
                    loaiViTri: null,
                    tenViTri: null,
                },
            });
            try {
                (await this.layDanhSachChuyenDi({})).Store;
            } catch (error) {}
            this.xoaDsLoTrinh();
            this.setOld({ key: "loTrinh", value: [] });
        },
        onKeyUpTimKiem(e) {
            this.xoaLocTheoDiaDiem();
            this.setModel({ key: "diemDenDangChon", value: null });
            if (e.event.target.value.length <= 0) {
                this.setDialogDsChonDiemDen(false);
            } else {
                this.setDialogDsChonDiemDen(true);
            }
            this.setTimKiem(e.event.target.value);
            if (this.tempTimeOutTk) {
                clearTimeout(this.tempTimeOutTk);
            }
            this.tempTimeOutTk = setTimeout(() => {
                this.layDanhSachDiemDen();
            }, 500);
        },
        onFocusInTimKiem() {
            this.tempTimKiem = this.getTimKiem;
            // this.setTimKiem("");
            if (this.getDataDsDiemDen.length > 0) {
                this.setDialogDsChonDiemDen(true);
            }
        },
        onFocusOutTimKiem() {
            // if (this.tempTimKiem) {
            //     this.setTimKiem(this.tempTimKiem);
            // }
        },
        async chonDiemDen(item) {
            this.$startLoading;
            this.xoaMarkers();
            this.setGuidNhaXe("");
            this.setModel({ key: "diemDenDangChon", value: item });
            this.setModel({ key: "timKiem", value: item.diaChi });
            this.setDialogDsChonDiemDen(false);
            try {
                (await this.layDanhSachChuyenDi({})).Store;
            } catch (error) {}
            if (this.tempTimKiem == item.diaChi) {
                return;
            }
            this.xoaDsLoTrinh();
            this.setOld({ key: "loTrinh", value: [] });
        },
        async clear() {
            this.setTimKiem("");
            this.setGuidNhaXe("");
            this.setTinhDangChon(null);
            this.setHuyenDangChon(null);
            this.setXaDangChon(null);
            this.getDsTinh.forEach((e) => {
                e.active = false;
            });
            this.getDsHuyen.forEach((e) => {
                e.active = false;
            });
            this.getDsXa.forEach((e) => {
                e.active = false;
            });
            this.set({ key: "boundsDiemDung", value: null });
            this.setDialogDsChonDiemDen(false);
            if (this.$refs.refTimKiem) {
                this.$refs.refTimKiem.instance.focus();
            }
            // try {
            //     (await this.layDanhSachLoTrinhSapXuatBen()).Store;
            // } catch (error) {}
        },
        boldSearch(item) {
            let divDiaChi =
                "<div class='row font-24'>" + item.diaChi + "</div>";
            let divViTri =
                "<div class='font-bold font-24'>" + item.tenViTri + "</div>";
            if (item && item.diaChi) {
                divDiaChi = divDiaChi.replaceAll(
                    item.tenViTri,
                    "&nbsp" + divViTri
                );
            }
            return divDiaChi;
        },
    },
    beforeUnmount() {
        this.setTimKiem("");
    },
};
</script>
<style lang='scss' scoped>
.search {
    position: fixed;
    top: 24px;
    height: 68px;
    z-index: 2;
    border-radius: 16px;
    width: 95%;
    box-shadow: $box_shadow;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}
::v-deep #btn-search .dx-icon {
    color: $secondary;
}
::v-deep #txt-search.dx-texteditor::after,
::v-deep #txt-search.dx-state-focused.dx-texteditor::before {
    content: unset !important;
}
::v-deep #txt-search .dx-placeholder,
::v-deep #txt-search input {
    font-size: 24px;
}
::v-deep.btn-style .dx-button .dx-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
}
::v-deep.btn-style i {
    font-size: 40px;
}
.btn-style {
    box-sizing: border-box;
    border: 1px solid $primary;
}
.content-diemden:active {
    background-color: $border;
}
.luachontimkiem {
    border: 1px solid $primary;
    width: 100%;
    position: relative;
    left: 0;
    box-sizing: border-box;
}
</style>